import React from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"

import egResume from "../assets/pdfs/elena-gonzalez-resume.pdf"

const Resume = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={10}>
              <h1 className="page-title">Resume</h1>
              <h3 className="resume-name">Elena G. González, PhD</h3>
              <p className="resume-download">
                <a href={egResume} download>
                  Download a PDF Version of this resume
                </a>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Genetics and Molecular Biology Expertise • Academic and
                  Industry R&D Experience • International
                </span>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Permanent US Resident / Green Card holder
                </span>
              </p>
              <p className="resume-text">
                Scientist with over 18 years of international experience in
                molecular biology, genetics, and human disease. Research,
                management, and communication skills cultivated across 25+
                interdisciplinary projects in the fields of oncology, genomics,
                and evolutionary biology. Solid experience attracting funding
                and communicating scientific outputs in international
                conferences and peer-reviewed journals. Passionate,
                enthusiastic, and supportive colleague able to build trust and
                establish rapport among team members.
              </p>
              <p className="resume-text">
                <span className="bold author">WORK EXPERIENCE</span>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Senior Research Scientist | Oncology
                </span>{" "}
                (Jun 2017 – July 2021)
                <br />
                Pharmamar SA, Microbiology Department, Spain
              </p>
              <ul className="resume-list">
                <li>
                  <span className="bold">
                    Scientific Project management and Communication:
                  </span>
                </li>
                <li>
                  Responsible for the acquisition, performance monitoring, and
                  technical functions of molecular biology instrumentation.
                  Communicated with industry peers and collaborators to execute
                  new laboratory processes and to devise solutions for the
                  improvement and transformation of the lab.{" "}
                </li>
                <li>
                  Prepared project reports, assisted with grant applications and
                  reports, reviewed manuscripts, and monitored project spending.
                </li>
                <li>
                  Responsible for the definition and reporting of the short and
                  medium term objectives related to projects within the drug
                  discovery process.
                </li>
                <li>
                  Supervised the data analyses and writing of 1 research
                  associate in his pursuit of a PhD degree within the
                  pharmaceutical industry, and delivered 3 peer-reviewed
                  articles.
                </li>
                <li>
                  Communicated research results to interdisciplinary audiences
                  in team meetings, conferences, and seminars.
                </li>
                <li>
                  <span className="bold">
                    Scientific and Technical Expertise:
                  </span>
                </li>
                <li>
                  Collaborated in the discovery of a novel molecule with
                  antitumor activity produced by a bacteria with a marine
                  origin, by performing genetic characterization (genome
                  annotation). A patent describing this molecule is in process.
                </li>
                <li>
                  Greatly enhanced drug discovery results using Machine learning
                  (ML) statistical analysis by modelling the optimal variables
                  within the microbiology and screening processes.{" "}
                </li>
                <li>
                  Established, optimized, and validated molecular assays and
                  (bio-)analytical methods for bacteria genome extraction, gene
                  editing, and molecular characterization during the drug
                  discovery process.
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold">
                  Postdoctoral Researcher | Molecular Biology and Genomics
                </span>{" "}
                (Jan 2013 – Apr 2016)
                <br />
                Natural History Museum, Spain
              </p>
              <ul className="resume-list">
                <li>
                  Envisioned and wrote 2 proposals to national research funding
                  agencies in the fields of biotechnology, biodiversity, and
                  conservation, successfully attracting 1.73M Euros in funding.
                  The approved projects operated within time and budgetary
                  constraints and according to good scientific practice (GsP) to
                  deliver a total of 5+ research peer-reviewed publications.
                </li>
                <li>
                  Reviewed research articles for 5+ scientific journals and
                  evaluated 3+ research proposals for NOAA-NMFS projects in the
                  USA.
                </li>
                <li>
                  Supervised 3 undergraduate students in project planning,
                  laboratory techniques, and data analysis. One student obtained
                  the Best Student Award in 2013 by The Autonomous University of
                  Madrid.
                </li>
                <li>
                  Helped to organize a national conference, one workshop, and
                  several scientific expeditions.
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold">
                  Postdoctoral Researcher | Molecular Biology and Genomics
                </span>{" "}
                (Jun 2011 – Jan 2013)
                <br />
                Centre for Marine Sciences, Portugal
              </p>
              <ul className="resume-list">
                <li>
                  Designed and implemented novel Machine Learning models on
                  genetic and proteomic population data for natural selection to
                  established demographic history inference of marine species.
                </li>
                <li>
                  Published 5+ peer reviews and 1 literature review (four as a
                  first author) and disseminated research outputs as poster and
                  talks at 8 international scientific conferences. Invited to
                  give a seminar at a International Summer course on
                  “hands-on-proteomics”.
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold">
                  Postdoctoral Researcher | Biochemistry and Evolutionary
                  Biology
                </span>{" "}
                (Feb 2008 – Jun 2011)
                <br />
                Complutense University, Spain
              </p>
              <ul className="resume-list">
                <li>
                  Led the Spanish team within a research project funded by the
                  European Commission (3Million € EC) which was formed by a
                  consortium of 15 Universities and Research Institutes from 8
                  EU countries and Russia, to build on advanced technologies for
                  fish product traceability and policy-related monitoring in the
                  fisheries sector. Prepared policy and report documents for
                  both scientific and non-specialist audiences. Studies resulted
                  in 5+ peer-reviewed publications, such as Nature Commun,
                  Journal of Proteomics, etc.
                </li>
                <li>
                  Worked as an external consultant on the writing of a report on
                  sustainability of aquatic genetic resources for the Fisheries
                  Agency Organization (FAO).
                </li>
                <li>
                  Recruited as an Assistant Professor and Professor at Public
                  and Private Universities by the official accreditation
                  agencies of Spain.
                </li>
                <li>
                  Spearheaded new applications of population proteomics for fish
                  traceability based on differential 2D-fluorescence (DIGE)
                  protein expression, benchmarking its applicability by the
                  characterization of new antibodies for marine fish of high
                  commercial interest. As a result, one patent was published.
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold">
                  Graduate Researcher | Molecular Biology
                </span>{" "}
                (Jul 2001 – Aug 2007)
                <br />
                Autonoma University of Madrid, Spain
              </p>
              <ul className="resume-list">
                <li>
                  Accomplished a PhD dissertation about population models in
                  marine pelagic fishes creating a timeline, identifying
                  problems, and designing experimental approaches for hypothesis
                  testing.
                </li>
                <li>
                  Cultivated international experience through scientific
                  research in institutions from the USA (Smithsonian
                  institution; Harvard University), UK (The Natural History
                  Museum of London; University of East Anglia), Portugal (ISPA,
                  Lisbon), Mexico (UNAM, DF), and Qatar (Qatar Environment and
                  Energy Research).
                </li>
                <li>
                  Collaborated actively in 15+ across National Research funding
                  and European agencies, managed the high-throughput data
                  obtained during these studies, and conducted statistical
                  analyses on the results.{" "}
                </li>
                <li>
                  Published 11+ peer-reviewed articles and participated in 12
                  international conferences concerning the topics of Genomics
                  and Genetic Conservation.
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold author">EDUCATION</span>
              </p>
              <ul className="resume-list">
                <li>
                  <span className="bold">
                    Ph.D in Molecular Biology, Doctor Europaeus
                  </span>{" "}
                  | Autonoma University of Madrid (Spain, 2007)
                </li>
                <li>
                  <span className="bold">M.Sc. in Conservation Genetics</span> |
                  Autonoma University of Madrid (Spain, 2004)
                </li>
                <li>
                  <span className="bold">B.Sc. in Biology</span> | Autonoma
                  University of Madrid (Spain, 2000)
                </li>
              </ul>
              <p className="resume-text">
                <span className="bold author">TECHNICAL SKILLS</span>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Molecular biology and gene identification:
                </span>{" "}
                Method development, validation and optimization of: DNA
                extraction, DNA amplification (PCR, RT-PCR), electrophoresis of
                DNA and proteins, fingerprint (REP-PCR), microsatellite and SNPs
                markers for individual typing, southern blot assays,
                spectroscopy (IR, UV/VIS, fluorospectroscopy), transcriptomics.
              </p>
              <p className="resume-text">
                <span className="bold">Proteomics and protein chemistry:</span>{" "}
                Protein purification and selective protein
                tagging/bioconjugation, antibobody preparation, purification and
                validation, quantitative proteomics (2D-DIGE), mass spectrometry
                (MALDI-TO/TOF), electrophoresis (SDS-PAGE, Western blot).
              </p>
              <p className="resume-text">
                <span className="bold">Cell biology:</span> Construction of
                enriched libraries for gene expression analyses, preparation and
                analysis of transformants, vector construct cloning.
              </p>
              <p className="resume-text">
                <span className="bold">Microbiology:</span> media preparation,
                bacteria cultivation, antimicrobial susceptibility testing (AST)
                methods, MIC assessment.
              </p>
              <p className="resume-text">
                <span className="bold">Genomics:</span> gene identification,
                annotation and analysis (e.g. secondary metabolite biosynthesis
                gene clusters in bacterial genome).
              </p>
              <p className="resume-text">
                <span className="bold">Software:</span> Experience in the use of
                R and Mosix-based cluster environments and other
                multiprocessor/multicore platforms. Experience in data
                management, Machine Learning applications, strong analytical and
                (bio) statistical skills and good understanding of
                bioinformatics (e.g. Bionumerics, Spotfire).
              </p>
              <p className="resume-text">
                <span className="bold">IT:</span> Windows, MacOS and Unix/Linux
                operating systems.
              </p>
              <p className="resume-text">
                <span className="bold author">PATENTS</span>
              </p>
              <p className="resume-text">
                <span className="bold">E. G. Gonzalez</span> & J. M. Bautista.
                2015. Development of specific antibodies for European hake
                (Merluccius merluccius) and a method to assign individuals to
                its population of origin. ES 2 526 468 A1.
              </p>
              <p className="resume-text">
                <span className="bold author">LANGUAGES</span>
              </p>
              <p className="resume-text">
                English (full professional proficiency) | Spanish (mother
                tongue)
              </p>
              <p className="resume-text">
                <span className="bold author">
                  VISITING RESEARCH EXPERIENCE
                </span>
              </p>
              <p className="resume-text">
                <span className="bold">
                  Qatar Environment and Energy Research (QEERI):
                </span>{" "}
                Doha, Qatar - 2012
              </p>
              <p className="resume-text">
                <span className="bold">
                  The Natural History Museum of London:
                </span>{" "}
                London, UK - 2008
              </p>
              <p className="resume-text">
                <span className="bold">Harvard University:</span> Cambridge, US
                - 2004
              </p>
              <p className="resume-text">
                <span className="bold">
                  Smithsonian Museum of Natural History:
                </span>{" "}
                Washington DC - 2003
              </p>
              <p className="resume-text">
                <span className="bold">University of East Anglia:</span>{" "}
                Norwich, UK - 2002
              </p>
              <p className="resume-text">
                <span className="bold author">RELEVANT TRAINING</span>
                <br />
                Courses and Workshops (Selection of the Most Recent):
              </p>
              <p className="resume-text">
                <span className="bold">Data science with R:</span> Madrid, Spain
                - 2019
              </p>
              <p className="resume-text">
                <span className="bold">Introduction to Deep Learning:</span>{" "}
                Madrid, Spain - 2018
              </p>
              <p className="resume-text">
                <span className="bold">
                  International basic training workshop on Bionumerics:
                </span>{" "}
                Ghent, Belgium - 2017
              </p>
              <p className="resume-text">
                <span className="bold">Digital Marketing Bootcamp:</span>{" "}
                Washington DC - 2016
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Resume
